<template>
  <div class="page-container">
    <div
      :style="{
        backgroundImage: 'url(' + bg3 + ')',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
        height: '650px',
      }"
    >
      <div class="shadow-layer">
        <div class="container">
          <div class="box">
            <div class="title">租特车<span>—</span>寻租需求</div>
            <div class="title1">Rent seeking demand</div>
            <div class="title2">
              客户的信赖就是我们最大的<span style="color: #fcb110">动力</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="need-list">
        <div class="tile_box">
          <div class="title">精选寻租中的项目</div>
        </div>
        <div class="swiper" v-if="nav1.length > 0">
          <div
            class="swiper-item"
            v-for="(item, index) in nav1"
            :key="index"
            @click="toDetail(item.id)"
          >
            <div class="title">
              <div class="bg">
                <img src="../../assets/images/m.png" alt="" />
              </div>
              <div class="">{{ item.projectName }}</div>
            </div>
            <div class="adres">
              <div class="projectDec">{{ item.projectDec }}</div>
              <div class="projectDec">
                <i class="el-icon-map-location"></i>
               {{ item.projectCity }} {{ item.projectCountry }}
              </div>
            </div>
            <div class="adres">
              <div>
                求租：<span v-for="(v, index1) in item.details" :key="index1"
                  >{{ v.vehicleName }}&nbsp;{{ v.vechileNumber }}台&nbsp;</span
                >
              </div>
            </div>
            <div class="time">
              <i class="el-icon-time"></i>{{ item.publishTime }}
            </div>
          </div>
        </div>
        <div class="pt" v-if="nav1.length > 0">
          <el-pagination
            background
            :page-size="10"
            layout="prev, pager, next"
            :total="total"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
        <el-empty :image-size="200" v-else></el-empty>
      </div>
    </div>
    <div class="container">
      <div class="need-list mb">
        <div class="tile_box">
          <div class="title">精选履约中的项目</div>
        </div>
        <div class="swiper" v-if="nav2.length > 0">
          <div
            class="swiper-item"
            v-for="(item, index) in nav2"
            :key="index"
            @click="toDetail(item.id)"
          >
            <div class="title">
              <div class="bg">
                <img src="../../assets/images/m.png" alt="" />
              </div>
              <div class="">{{ item.projectName }}</div>
            </div>
            <div class="adres">
              <div>{{ item.projectDec }}</div>
              <div>
                <i class="el-icon-map-location"></i>
                {{ item.projectProvince }}{{ item.projectCity }}
              </div>
            </div>
            <div class="adres">
              <div>
                求租：<span v-for="(v, index1) in item.details" :key="index1"
                  >{{ v.vehicleName }}&nbsp;{{ v.vechileNumber }}台&nbsp;</span
                >
              </div>
            </div>
            <div class="time">
              <i class="el-icon-time"></i>{{ item.publishTime }}
            </div>
          </div>
        </div>
        <el-empty :image-size="200" v-else></el-empty>
      </div>
    </div>
    <!-- 底部     -->
    <elFooter></elFooter>
  </div>
</template>

<script>
import elFooter from '@/components/footer'
import { getNeedList, getNextList } from '@/api/car'
let that

export default {
  data() {
    return {
      total: 0,
      bg3: require('@/assets/images/xq.png'),
      nav1: [],
      nav2: [],
      jsonData: {
        page: 1,
        pageSize: 10,
      },
    }
  },
  components: { elFooter },

  beforeCreate() {
    that = this
  },
  created() {
    this.getNeedList()
    this.getNextList()
  },
  methods: {
    getNeedList() {
      getNeedList(this.jsonData).then((res) => {
        this.nav1 = res.data.list
        this.total = res.data.total
      })
    },
    getNextList() {
      getNextList({}).then((res) => {
        this.nav2 = res.data
      })
    },
    toDetail(id) {
      this.$router.push({
        path: '/needs/detail1',
        query: {
          id: id,
        },
      })
    },
    handleCurrentChange(val) {
      this.jsonData.page = val
      this.getNeedList()
    },
  },
}
</script>

<style lang="stylus" scoped>
::v-deep .el-pagination.is-background .el-pager li
  border-radius 50%
::v-deep .el-pagination.is-background .btn-prev
  border-radius 50%
::v-deep .el-pagination.is-background .btn-next
  border-radius 50%
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active
  background #fcb110
.projectDec
  overflow hidden
  white-space nowrap
  text-overflow ellipsis
.mb
  margin-bottom 40px
.page-container
  background #fff
  .shadow-layer
    padding 50px 0
    background transparent
    display flex
    justify-content center
    .box
      margin auto
      margin-top 40px
      width 500px
      height 220px
      border 1px solid #acbcdc
      border-radius 5px
      background-color #305db6
      text-align center
      .title
        font-size 30px
        margin 40px 0 20px 0
        span
          margin 0 5px
      .title1
        font-size 20px
        margin auto
        width 200px
        line-height 30px
        border-bottom 1px solid #acbcdc
      .title2
        font-size 20px
        margin-top 20px
  .pt
    padding 40px 0 100px 0
    display flex
    justify-content center
  .need-list
    margin-top 50px
    font-size 14px
    color #333
    .tile_box
      border-bottom 2px solid #DDDDDD
      position relative
      .title
        position absolute
        top -30px
        font-size 18px
        font-weight bold
        border-bottom 2px solid #FCB110
        width 145px
        line-height 30px
    .swiper
      margin-top 20px
      display flex
      flex-wrap wrap
      .swiper-item
        border 1px solid #ddd
        padding 20px
        width 24%
        margin-right 10px
        margin-bottom 10px
        .title
          display flex
          align-items center
          font-size 18px
          font-weight 400
          .bg
            width 50px
            height 50px
            background #3c60bc
            border-radius 50%
            margin-right 20px
            display flex
            align-items center
            justify-content center
            img
              object-fit cover
        .adres
          display flex
          align-items center
          margin-top 10px
          justify-content space-between
          color #999
        .adres:nth-child(3)
          background #eff3fd
          padding 5px 2px
        .time
          margin-top 10px
          text-align right
</style>